import React from "react"
import { SEO } from "../components/atoms/SEO"
import { HomePage } from "../components/sets/home/HomePage"

const Home: React.FC = () => {
  return (
    <>
      <SEO />
      <HomePage />
    </>
  )
}

export default Home
